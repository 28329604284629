<template>
  <div class="layout-maincontainer" style="height: 100%">
    <h1 class="tit-h1">채팅상담메인</h1>
    <div class="layout-chatmain type-chat">
      <!-- //mark: 채팅목록 -->
      <div class="layout-chatmain--cs layout-chatmain-box ml-0">

        <div class="layout-chatmain--cs-ct">
          <!-- 상담목록 -->
          <div class="d-flex py-3">
             <div class="form-inp pt-0">
              <v-select
                :items="USER_STAT"
                item-text="CD_NM"
                item-value="CD"
                v-model="USER_NEWSTAT"
                outlined
                placeholder="선택하세요"
                hide-details
                v-on:change="changeUserStat(true)"
              ></v-select>
            </div>

            <div class="ml-auto">
              <!-- 새로고침 버튼 -->
              <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
                plain
                v-on:click="getTalkList"
                >
                <v-icon small class="svg-main-refresh"></v-icon>
              </v-btn>

            </div>

          </div>

          <div class="layout-chatmain--cs-ct--btn">
            <v-btn-toggle
              v-model="toggle_csCtBtn"
            >
              <v-btn class="cs-ct-btn" @click="FnChatListAll">전체 <span class="cs-cti-btn--cnt">{{ chatList.length }}</span></v-btn>
              <v-btn class="cs-ct-btn" @click="FnChatListIng">상담 <span class="cs-cti-btn--cnt">{{ chatListIng.length }}</span></v-btn>
              <v-btn class="cs-ct-btn" @click="FnChatListDone">종료 <span class="cs-cti-btn--cnt">{{ chatListDone.length }}</span></v-btn>
              <v-btn class="cs-ct-btn" @click="FnChatListAfter">후처리 <span class="cs-cti-btn--cnt">{{ chatListAfter.length }}</span></v-btn>
              <v-btn class="cs-ct-btn" @click="FnChatListWait">상담대기 <span class="cs-cti-btn--cnt">{{ chatListWait.length }}</span></v-btn>
            </v-btn-toggle>

          </div>
          <div class="layout-chatmain--cs-ct--box type02" :class="[chatListFilter, chatFilter === true ? 'type03' : '']" >
            <div class="layout-chatmain--cs-ct--talk"
              :class="`type-${item.type} ${ item.countdown === true ? 'countdown' : '' }`"
              v-for="(item,index) in chatList"
              :key="index"
              v-show="chatListFilter === 'all' || chatListFilter === item.type"
              v-on:click="clickChat(index)"
              >
              <!-- 채널 -->
              <v-icon :class="'svg-chatmain-'+item.CHN_ICON"></v-icon>

              <div class="d-flex align-center">
                <!-- 키? -->
                <div class="layout-chatmain--cs-ct--key ml-2">{{ item.TALK_USER_KEY }}</div>
                <!-- 회사 -->
                <div class="layout-chatmain--cs-ct--stat ml-2">{{ item.ASP_SENDER_KEY_NM }}</div>
                <!-- 상담상태 -->
                <div class="layout-chatmain--cs-ct--stat ml-2" :class="{'statChat': item.statChat === true}">{{ item.TALK_STAT_NM }}</div>
                <!-- 상담시간 -->
                <div class="layout-chatmain--cs-ct--time ml-2">{{ item.TALK_DIST_DT }}</div>
              </div>
              <!-- 상담내역 -->
              <div class="layout-chatmain--cs-ct--text">
                {{ item.TALK_TITLE }}
              </div>
            </div>
          </div>

        </div>
      </div>
      <!--// 채팅목록 -->

      <!-- //mark: 채팅 창 -->
      <div class="layout-chatmain--talk">
        <div class="layout-chatmain--chat-wrap">
          <!--//mark: 채팅창 -->
          <div class="layout-chatmain--chat-history-wrap">
            <h2 class="tit-h2 d-flex align-center tit-header" style="border-bottom: 0">
              {{CLK_CUST}}
              <div class="ml-auto">
                <!-- 새로고침 버튼 -->
                <v-btn dark small class="btn-main-refresh pa-0" min-width="32px" height="32px"
                  plain
                  v-on:click="getTalkInfo"
                  >
                  <v-icon small class="svg-main-refresh"></v-icon>
                </v-btn>
                <!-- 엑셀 다운로드 버튼 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="downloadExcel(talkHistory)"
                    >
                    <v-icon small class="svg-main-down"></v-icon>
                  </v-btn>
                  </template>
                  <span>엑셀 다운로드</span>
                </v-tooltip>
              </div>
            </h2>
            <div class="layout-chatmain--chat-history layout-chatmain-box" >
              <div class="layout-chatmain--chat-history-box"
                v-scroll.self="onScroll"
                :class="{ 'fz10' : chatHistoryTextsize == '100', 'fz12' : chatHistoryTextsize == '120', 'fz14' : chatHistoryTextsize == '140'}"
              >
                <div class="text-center mt-5 pr-0">
                  <v-btn outlined x-small class="btn-round">이전대화내역</v-btn>
                </div>
                <template v-for="(item,index) in talkHistory">
                  <div :class="item.SNDRCV_CD == 'RCV' ? 'layout-chatmain--chat-history--cs-msg' : 'layout-chatmain--chat-history--manager-msg' " :key="index">
                    <v-img v-if="item.TYPE=='photo'" :src="item.CONTENT" @click="setViewAllImg(item)"></v-img>
                    <!-- <img v-if="item.TYPE=='IM'" v-auth-image="getImageSrc(item)" @click="setViewAllImg(item)"></img> -->
                    <div v-if="item.TYPE!='photo' && item.TYPE!='IM'">
                      {{ item.CONTENT }}
                    </div>
                    <div v-if="item.TYPE!='photo' || item.TYPE!='IM'" class="layout-chatmain--chat-history--time"  :key="index">{{item.CHAT_DATE}}</div>
                  </div>

                <v-dialog
                        v-model="dialogTotalImage"
                        content-class="has-body-scroll has-img"
                        :retain-focus="false"
                    >
                    <div class="tt-dialog-header">
                        <h1 class="tit-h1">이미지 보기</h1>
                        <div class="is-right">
                            <button type="button" class="tt-btn is-icon" title="닫기" @click="closeDialogTotalImage"><i class="tt-icon-close"></i></button>
                        </div>
                    </div>
                    <div class="tt-dialog-body"><img :src="img_src" ></div>
                    
                </v-dialog>

                </template>
                <!-- 맨위로 -->
                <v-btn icon
                  class="btn-to-top"
                  :class="scroll>0 ? 'active' : null"
                  @click="scrlToTop"
                ><v-icon class="svg-to-top"></v-icon></v-btn>
              </div>
            </div>

            <!-- 버튼 -->
            <div class="layout-chatmain--chat-history-btn">
              <v-btn outlined class="btn-default" v-on:click="chatTemp = !chatTemp">
                임시저장
              </v-btn>
              <v-btn outlined class="btn-etc ml-2" v-on:click="showAlertEndTalk">
                상담종료
              </v-btn>
            </div>
            <!-- 입력 form -->
            <div class="layout-chatmain--chat-history--form ">
              <v-textarea
                label=""
                no-resize
                outlined
                placeholder="내용을 입력하세요"
                background-color="white"
                v-bind:disabled="CLK_STAT != '12' && CLK_STAT != '11'"
                dense
                class="layout-chatmain--chat-history--form-textarea"
                v-model.trim="CHAT_MSG"
                v-on:keyup.enter="sendMsg"
              ></v-textarea>
            </div>
            <!-- 기능 버튼 -->
            <div class="layout-chatmain--chat-history--func">
              <div >
                <!-- 이미지 -->
                <v-btn dark small class="btn-main-chatfunc pa-0" min-width="32px" height="32px"
                  plain
                  v-on:click="showDialog('M110102P01')"
                >
                  <v-icon small class="svg-main-chatfunc-img"></v-icon>
                </v-btn>
                <!-- 시스템메시지중단 -->
                <v-btn dark small class="btn-main-chatfunc pa-0 ml-2" min-width="32px" height="32px"
                  plain
                  v-on:click="SystemMsgSkip"
                >
                  <v-icon small class="svg-main-chatfunc-stop"></v-icon>
                </v-btn>
                <!-- 종료링크전송 -->
                <v-btn dark small class="btn-main-chatfunc pa-0 ml-2" min-width="32px" height="32px"
                  plain
                  v-on:click="sendEndLink"
                >
                  <v-icon small class="svg-main-chatfunc-send"></v-icon>
                </v-btn>
                <!-- 챗 이관 -->
                <v-btn dark small class="btn-main-chatfunc pa-0 ml-2" min-width="32px" height="32px"
                  plain
                  @click="[getUserSelect(), chatTrans = !chatTrans]"
                >
                  <v-icon small class="svg-main-chatfunc-trans"></v-icon>
                </v-btn>
                <!-- 챗 콜백 -->
                <v-btn dark small class="btn-main-chatfunc pa-0 ml-2" min-width="32px" height="32px"
                  plain
                  @click="chatCallback = !chatCallback"
                >
                  <v-icon small class="svg-main-chatfunc-reserve"></v-icon>
                </v-btn>
                <!-- 글자크기 -->
                <v-btn dark small class="btn-main-chatfunc pa-0 ml-2" min-width="32px" height="32px"
                  plain
                  @click="chatFontsize = !chatFontsize"
                >
                  <v-icon small class="svg-main-chatfunc-size"></v-icon>
                </v-btn>
                <!-- 모달 : 글자크기  -->
                <div class="layout-chatmain--chat-history--func-size" :class="{ active : chatFontsize == true }">
                  <div class="form-inp pa-0 full" >
                    <v-select
                      :items="dropItemTextsize"
                      item-text="title"
                      item-value="size"
                      width="120px"
                      outlined
                      placeholder="글자크기선택"
                      @input="FnChatHistoryTextsize"
                      hide-details>
                      <template slot="selection" slot-scope="data">
                        <div class="v-select__selection v-select__selection--comma">
                          {{ data.item.title }}
                        </div>
                      </template>
                      <template slot="item" slot-scope="data">
                        <div class="v-list-item__title" style="flex-basis: auto">
                          {{ data.item.title }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>

              </div>
              <!-- 전송 -->
              <v-btn outlined class="btn-point ml-auto" v-on:click="sendMsg">
                전송
              </v-btn>

            </div>

            <!-- //mark: 채팅 전달 바텀시트 -->
            <div class="layout-chatmain--chatCallback" :class="{ active : chatTrans == true }">
              <h2 class="tit-h2 mt-3 type02">전달</h2>
              <div class="mt-3">
                <div class="d-flex align-center">
                    <div class="form-inp full pt-0">
                      <v-select
                        v-model="USER_NEWID"
                        :items="USER_ID"
                        no-data-text="일치하는 데이터가 없습니다."
                        item-text="USER_ID"
                        item-value="USER_ID"
                      >
                        <template v-slot:label>
                          상담사선택
                          <v-icon color="#EF9191">mdi-circle-small</v-icon>
                        </template>
                      </v-select>
                    </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select  outlined placeholder="상담유형1" hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select  outlined placeholder="상담유형2" hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                      >
                      <template v-slot:label>
                        상담유형2
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select  outlined placeholder="상담유형3" hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                      >
                      <template v-slot:label>
                        상담유형3
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select outlined placeholder="상담유형4" hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                      >
                      <template v-slot:label>
                        상담유형4
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name" label="고객명" id="id" outlined
                    v-model="CHT_TIT"
                    hide-details
                    >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea label="" no-resize outlined rows="5" hide-details placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn outlined class="btn-default" @click="[chatTrans = false, initCnsl()]">
                    취소
                  </v-btn>
                  <v-btn outlined class="btn-point ml-2" 
                    v-on:click="confirmTrans"
                  >
                    전달
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- //mark: 채팅 전달 바텀시트 -->


            <!-- //mark: 채팅 콜백 바텀시트 -->
            <div class="layout-chatmain--chatCallback" :class="{ active : chatCallback == true }">
              <h2 class="tit-h2 mt-3 type02">콜백</h2>
              <div class="mt-3">
                <div class="d-flex align-center">
                    <div class="form-inp full pt-0">
                      <v-select
                        :items="CHT_RESULT"
                        item-text="CD_NM"
                        item-value="CD"
                        v-model="CHT_NEWRESULT"
                        outlined
                        hide-details
                        placeholder="선택하세요"
                      >
                        <template v-slot:label>
                          상담결과
                          <v-icon color="#EF9191">mdi-circle-small</v-icon>
                        </template>
                      </v-select>
                    </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <v-checkbox :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'" label="콜백예약시간" hide-details></v-checkbox>
                  <v-menu v-model="chatCalendar2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computedDateFormatted2" v-bind="attrs" v-on="on" outlined hide-details
                        class="form-inp icon-calendar pt-0 ml-2" append-icon="svg-calendar"></v-text-field>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker v-model="date" dark color="#F7AFAE" no-title show-current="false"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker v-model="timeStep" :allowed-minutes="allowedStep" color="#F7AFAE" format="24hr">
                      </v-time-picker>
                    </div>
                    <div class="text-center pt-3 pb-3">
                      <v-btn outlined small class="btn-point ml-3" @click="chatCalendar2 = false">확인</v-btn>
                    </div>
                  </v-menu>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select outlined placeholder="콜백예약시간" hide-details>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select outlined placeholder="콜백예약분" hide-details>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select  outlined placeholder="상담유형1" hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select  outlined placeholder="상담유형2" hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                      >
                      <template v-slot:label>
                        상담유형2
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select  outlined placeholder="상담유형3" hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                      >
                      <template v-slot:label>
                        상담유형3
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select outlined placeholder="상담유형4" hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                      >
                      <template v-slot:label>
                        상담유형4
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name" label="고객명" id="id" outlined
                    v-model="CHT_TIT"
                    hide-details
                    >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea label="" no-resize outlined rows="5" hide-details placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn outlined class="btn-default" @click="[chatCallback = false, initCnsl()]">
                    취소
                  </v-btn>
                  <v-btn outlined class="btn-point ml-2" 
                    v-on:click="confirmSave('4','콜백을 저장하시겠습니까?')"
                  >
                    저장
                  </v-btn>
                </div>

              </div>
            </div>
            <!-- //mark: 채팅 콜백 바텀시트 -->

            <!-- //mark: 채팅 임시저장 바텀시트 -->
            <div class="layout-chatmain--chatDone" :class="{ active : chatTemp == true }">
              <h2 class="tit-h2 mt-3 type02">임시저장</h2>
              <div class="mt-3">
               <div class="d-flex justify-space-between align-center">
                  <div class="form-inp full pa-0">
                    <v-select
                      :items="CHT_RESULT"
                      item-text="CD_NM"
                      item-value="CD"
                      v-model="CHT_NEWRESULT"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                    >
                      <template v-slot:label>
                        상담결과
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select  outlined placeholder="상담유형1" hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                      >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select  outlined placeholder="상담유형2" hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                      >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select  outlined placeholder="상담유형3" hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                      >
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select outlined placeholder="상담유형4" hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                      >
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name" label="고객명" id="id" outlined
                    v-model="CHT_TIT"
                    hide-details
                    >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea label="" no-resize outlined rows="5" hide-details placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn outlined class="btn-default" v-on:click="[chatTemp = false, initCnsl()]">
                    취소
                  </v-btn>
                  <v-btn outlined  class="btn-point ml-2" v-on:click="confirmSave(CHT_NEWRESULT,'상담을 임시저장하시겠습니까?')">
                    저장
                  </v-btn>
                </div>
              </div>
            </div>
            <!--// 채팅 임시저장 바텀시트 -->

            <!-- //mark: 채팅 상담완료 바텀시트 -->
            <div class="layout-chatmain--chatDone" :class="{ active : chatDone == true }">
              <h2 class="tit-h2 mt-3 type02">상담완료</h2>
              <div class="mt-3">
               <div class="d-flex justify-space-between align-center">
                  <div class="form-inp full pa-0">
                    <v-select 
                      :items="CHT_RESULT"
                      item-text="CD_NM"
                      item-value="CD"
                      v-model="CHT_NEWRESULT"
                      outlined
                      hide-details
                      placeholder="선택하세요"              
                    >
                      <template v-slot:label>
                        상담결과
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select  outlined placeholder="상담유형1" hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                      >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select  outlined placeholder="상담유형2" hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                      >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select  outlined placeholder="상담유형3" hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                      >
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select outlined placeholder="상담유형4" hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                      >
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name" label="고객명" id="id" outlined
                    v-model="CHT_TIT"
                    hide-details
                    >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea label="" no-resize outlined rows="5" hide-details placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn v-if=false outlined class="btn-default" v-on:click="[chatDone = false, initCnsl()]">
                    취소
                  </v-btn>
                  <v-btn outlined class="btn-point ml-2" v-on:click="confirmSave(CHT_NEWRESULT,'상담이력을 저장하시겠습니까?')">
                    저장
                  </v-btn>
                </div>
              </div>


            </div>
            <!--// 채팅 상담완료 바텀시트 -->
          </div>
          <!--// 채팅창 -->
        </div>
      </div>
      <!--// 채팅 창 -->

      <!-- 고객정보, 스크립트? -->
      <div class="layout-chatmain--talk" >
        <div>
          <div class="layout-chatmain-box px-3">
            <h2 class="tit-h2 d-flex align-center pt-3">
              고객정보
            </h2>
            <div class="pt-3 pb-3">
              <div>
                <v-text-field
                  class="form-inp full"
                  name="name" label="고객명" id="id" outlined
                  v-model="USER_NM"
                  hide-details
                  >
                </v-text-field>
              </div>
              <div class="mt-2">
                <v-text-field
                  class="form-inp full"
                  name="name" label="고객전화번호" id="id" outlined
                  v-model="USER_TEL_NO"
                  hide-details
                  >
                  <template v-slot:label>
                    고객전화번호
                    <v-icon color="#EF9191">mdi-circle-small</v-icon>
                  </template>
                </v-text-field>
              </div>
              <div class="mt-2">
                <v-checkbox :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'"
                  v-model="CHK_BL" 
                  label="B/L여부" hide-details></v-checkbox>
              </div>
              <div class="mt-2">
                <v-textarea label="" no-resize outlined height="100px" hide-details 
                  v-model="USER_MEMO"
                  placeholder="관리메모"></v-textarea>
              </div>
              <div class="mt-2 text-right">
                <v-btn outlined class="btn-point" v-on:click="showAlertReg">저장</v-btn>
              </div>
            </div>

          </div>
          <!-- 스크립트 정보? -->
          <div class="layout-chatmain-box pa-3" style="height: 414px;">
            <h2 class="tit-h2 d-flex align-center">
              스크립트정보
            </h2>
            <div class="pt-3">
              <div class="form-inp sm">
                <v-select
                  v-model="CNSL_NEWDIV"
                  :items="CNSL_DIV"
                  item-text="title"
                  outlined
                  hide-details
                  placeholder="선택하세요"
                  label="분류구분"
                >
                  <template v-slot:label>
                    분류구분
                  </template>
                </v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-text-field
                    class="form-inp sm"
                    name="name" label="조회년도" outlined hide-details
                    v-model="SEARCH_CONT">
                    <template v-slot:label>
                      스크립트명
                    </template>
                  </v-text-field>
                </div>
              <v-btn outlined class="btn-etc2 ml-3" v-on:click="getChatScript">조회</v-btn>
            </div>

            <div v-show="is_use_command" style="position:absolute;">      
            <h2>단축키 설정</h2>
            <table class="tt-tbl-detail">
                <colgroup>
                    <col width="80px">
                    <col>
                </colgroup>
                <tbody>
                    <tr>
                        <th><label>이어붙이기</label></th>
                        <td> 
                            <div class="cols">
                                <v-text-field
                                    placeholder="단축키를 입력하세요"
                                    solo
                                    v-on:keyup.ctrl="checkCommand('ctrl', 'ADD', $event)" 
                                    v-on:keyup.alt="checkCommand('alt', 'ADD', $event)" 
                                    v-on:keyup.shift="checkCommand('shift', 'ADD', $event)" 
                                    v-model="script_info.ADD_COMMAND"
                                    maxlength="6"
                                >
                                </v-text-field>
                                <button type="button" class="tt-btn" @click="script_info.ADD_COMMAND=''">초기화</button>
                            </div>
                            <div class="info-txt " :class="addCommandDesc != '' ? '' : 'is-hidden'">{{script_info.ADD_COMMAND}}
                                {{addCommandDesc}}</div>
                        </td>
                    </tr>
                    <tr>
                        <th><label>새로붙이기</label></th>
                        <td> 
                            <div class="cols">
                                <v-text-field
                                    placeholder="단축키를 입력하세요"
                                    solo
                                    v-on:keyup.ctrl="checkCommand('ctrl', 'NEW', $event)" 
                                    v-on:keyup.alt="checkCommand('alt', 'NEW', $event)" 
                                    v-on:keyup.shift="checkCommand('shift', 'NEW', $event)" 
                                    v-model="script_info.NEW_COMMAND"
                                    maxlength="6"
                                >
                                </v-text-field>
                                <button type="button" class="tt-btn" @click="script_info.NEW_COMMAND=''">초기화</button>
                            </div>
                            <div class="info-txt " :class="newCommandDesc != '' ? '' : 'is-hidden'">{{script_info.NEW_COMMAND}}
                                {{newCommandDesc}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="btn-area">
                <button type="button" class="tt-btn" @click="closeScriptForm">취소</button>
                <button type="button" class="tt-btn is-main" @click="saveScriptForm">저장</button>
            </div>   
            </div>         

             <div class="tt-script-detail" v-if="scriptActive">
                <div class="tt-script-detail-toolbar">        
                    <!-- <button type="button" class="tt-btn is-icon" title="단축키설정" @click="scriptFormClick(true, false, true)"><i class="tt-icon-chat-key"></i>단</button>
                    <button type="button" class="tt-btn is-icon" title="이어붙이기 (드래그하여 버튼을 누를경우, 선택된 부분의 내용만 입력됩니다)" @click="putScript('add')"><i class="tt-icon-chat-push"></i>이어</button>
                    <button type="button" class="tt-btn is-icon" title="새로붙이기 (드래그하여 버튼을 누를경우, 선택된 부분의 내용만 입력됩니다)" @click="putScript('new')"><i class="tt-icon-chat-push-new"></i>새로</button> -->
                    <v-btn dark small class="" min-width="32px" height="20px"
                      plain
                      v-on:click="scriptFormClick(true, false, true)"
                      title="단축키설정"
                    >
                    <v-icon small class="svg-arrow-top"></v-icon>
                    </v-btn>                    
                    <v-btn dark small class="" min-width="32px" height="20px"
                      plain
                      v-on:click="putScript('add')"
                      title="이어붙이기 (드래그하여 버튼을 누를경우, 선택된 부분의 내용만 입력됩니다)"
                    >
                    <v-icon small class="svg-arrow-left"></v-icon>
                    </v-btn>                    
                    <v-btn dark small class="" min-width="32px" height="20px"
                      plain
                      v-on:click="putScript('new')"
                      title="새로붙이기 (드래그하여 버튼을 누를경우, 선택된 부분의 내용만 입력됩니다)"
                    >
                    <v-icon small class="svg-arrow-right"></v-icon>
                    </v-btn>                    
                    <v-btn dark small class="" min-width="32px" height="20px"
                      plain
                      v-on:click="closeDetail"
                      style="float:right"
                      title="닫기"
                    >
                    <v-icon small class="svg-close20"></v-icon>
                    </v-btn>
                </div>
                <div class="tt-script-detail-body">
                    <h1>{{SCP_DET_TIT}}</h1>
                    <label @mouseup="selectTextScript">{{SCP_DET_CON}}</label>
                     <!-- <label v-if="getSelectedScriptInfo.ADD_SHORT_KEY != null && getSelectedScriptInfo.ADD_SHORT_KEY != ''">{{getSelectedScriptInfo.ADD_SHORT_KEY}}</label>
                        <label v-if="getSelectedScriptInfo.NEW_SHORT_KEY != null && getSelectedScriptInfo.NEW_SHORT_KEY != ''">{{getSelectedScriptInfo.NEW_SHORT_KEY}}</label></h1> 
                     <label style="display: inline-block; word-break: break-all; white-space: pre-wrap;">{{getSelectedScriptInfo.SCRIPT_RMK | restoreXSSFilter}}</label> 영역지정하여 붙이는 기능 비활성화, 스크립트 영역 외에서 영역을 푸는 경우 복사되지 않는 버그 있음  -->
                </div>
            </div> 

            <v-data-table
              dense
              :height="scriptTableHeight"
              fixed-header
              :headers="gridScriptHeader"
              :items="gridScriptBody"
              :items-per-page="30"
              item-key="name"
              :page.sync="page"
              hide-default-footer
              class="grid-default"
              calculate-widths
              @page-count="pageCount = $event"
              @click:row="scriptRowClick"
              :item-class="isActiveRow_SCP"
            >
            </v-data-table>
            <div class="grid-paging text-center">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
            <div class="">
            
            <!-- <div class="d-flex align-stretch">
              <span class="in-label col-4">분류명</span>
              <span class="in-txt">
                {{SCP_DETTY_NM}}
              </span>
            </div>
            <div class="d-flex align-stretch">
              <span class="in-label col-4">스크립트명</span
              >
              <span class="in-txt">
                {{SCP_DET_TIT}}
              </span>
            </div>
            <div class="d-flex align-stretch">
              <span class="in-label col-4">스크립트 내용</span>
              <span class="in-txt">
                {{SCP_DET_CON}}
              </span>
            </div> -->
          </div>
          </div>
        </div>
      </div>

      <!--// 고객정보, 스크립트? -->

      <!--//mark: 고객상담이력-->
      <div class="layout-chatmain--talk">
        <div class="layout-chatmain--chat-wrap">
          <div class="layout-chatmain--chat-info layout-chatmain-box " style="height: 100%">
            <div class="">
              <h2 class="tit-h2 d-flex align-center py-3 pb-0">
                고객상담이력
                <div class="ml-auto">
                  <div class="form-inp pt-0">
                    <v-select
                      :items="CNSL_TY"
                      item-text="CD_NM"
                      item-value="CD"
                      v-model="CNSL_NEWTY"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                    ></v-select>
                  </div>
                  <!-- 새로고침 버튼 -->
                  <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
                    plain
                    v-on:click="getTalkHist"
                    >
                    <v-icon small class="svg-main-refresh"></v-icon>
                  </v-btn>
                </div>
              </h2>
            </div>
            <!-- 고객상담이력 컨탠츠 -->
            <div class="layout-chatmain--cs-info-ct pt-0">

              <!-- 상담이력 목록 -->
              <div class="layout-chatmain--cs-info-history-wrap">
                <div class="layout-chatmain--cs-info-history" :class="{ active: item.EXPAND}"
                  v-for="( item ) in chatHistory" :key="item.ID">
                  <div class="layout-chatmain--cs-info-title"
                    @click="chatmainCsToggle(item)"
                  >
                    <v-icon :class="item.ICON"></v-icon>
                    <span class="layout-chatmain--cs-info-history-category" >
                      {{ item.CNSL_TYP }} &gt;
                      {{ item.CNSL_TYP2 }}
                    </span>
                    <span class="layout-chatmain--cs-info-history-date">{{ item.REG_DTTM }}</span>
                    <span class="layout-chatmain--cs-info-history-manager ml-1">{{ item.USER_NM }}</span>
                    <div class="layout-chatmain--cs-info-history-summary" >{{ item.HST_CONTEXT }}</div>
                  </div>
                  <!-- 펼쳤을때 컨텐츠 -->
                  <div class="layout-chatmain--cs-info-detail">
                    <div class="layout-chatmain--cs-info-result">
                      처리결과 : {{ item.PROC_CODE_NM }}
                    </div>
                    <div class="d-flex align-center mt-2">
                      <v-textarea class="" no-resize outlined rows="3"
                        background-color="white"
                        hide-details :value="item.TALK_CONTEXT"></v-textarea>
                    </div>
                    <!-- 채팅창 불러오기 내역 -->
                    <div class="layout-chatmain--chat-history-wrap type02">
                      <div class="layout-chatmain--chat-history layout-chatmain-box" >
                        <div class="layout-chatmain--chat-history-box"
                          v-scroll.self="onScroll"
                          :class="{ 'fz10' : chatHistoryTextsize == '100', 'fz12' : chatHistoryTextsize == '120', 'fz14' : chatHistoryTextsize == '140'}"
                        >
                          <template v-for="(item,index) in talkHistList[item.ID-1]" >
                            <div :class="item.SNDRCV_CD == 'RCV' ? 'layout-chatmain--chat-history--cs-msg' : 'layout-chatmain--chat-history--manager-msg' " :key="index">

                              <v-img v-if="item.TYPE=='photo'" :src="item.CONTENT" @click="setViewAllImg(item)"></v-img>
                              <!-- <img v-if="item.TYPE=='IM'" v-auth-image="getImageSrc(item)" @click="setViewAllImg(item)"></img>                                 -->
                              <div v-if="item.TYPE!='photo' && item.TYPE!='IM'">
                                {{ item.CONTENT }}
                              </div>                          
                              <div class="layout-chatmain--chat-history--time">{{item.CHAT_DATE}}</div>
                            </div>
                          </template>
                        </div>
                        <div class="text-center mt-5">
                          <v-btn outlined x-small class="btn-round">상담이 종료되었습니다.</v-btn>
                        </div>
                      </div>
                    </div>
                    <!--// 채팅창 불러오기 내역 -->
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!--// 챗 상담정보 -->
      </div>
      <!--// 고객상담이력-->
    </div>
      <!-- 이미지 전송 모달창 -->
      <div class="">
       <v-dialog
          max-width="655"
          max-height="655"
          v-model="DialogM110102P01"
          content-class="square-modal min-auto"
        >
          <dialog-M110102P01
              headerTitle="이미지 전송"
              @hide="hideDialog('M110102P01')"
              :Imgdata="Imgdata"
              @input="Imgdata = $event"
              @selImg="getTalkInfo(),hideDialog('M110102P01')"
          >
          </dialog-M110102P01>
          <template v-slot:body>

          </template>
        </v-dialog>
      </div>
      <!-- 이미지 전송 모달창 -->
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import api from "@/store/apiUtil.js";
import apiChat from "@/store/apiChatParamUtil.js";
import DialogM110102P01 from "./M110102P01.vue";
import moment from "moment";
import Vue from "vue";
// import VueAuthImage from 'vue-auth-image';
import axios from "axios";
import { mapGetters } from "vuex"

import { eventBus } from "@/store/eventBus.js";           //Event Bus 
import { stompUtil }  from "@/store/stomp-util.js";

import chatStore from "@/store/modules/chatStore.js";

// Vue.use(VueAuthImage);

  export default {
    name: "MENU_M110102", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
      DialogM110102P01,
    },
    data() {
      return {
        DialogM110102P01: false,

        menu2: false,
        menu5: false,

        chatHistoryTextsize: 100,
        dropItemTextsize: [{
            title: '100%',
            size: 100
          },
          {
            title: '120%',
            size: 120
          },
          {
            title: '140%',
            size: 140
          },
        ],
        dropItemsCompany: [{
            title: 'A 업체명'
          },

        ],
        dropItemsResult: [{
            CD_NM: '완료'
          },
          {
            title: '미처리'
          },
          {
            title: '처리중'
          },
        ],
        USER_STAT: [
          { CD_NM: '채팅OFF', CD: 'CHATOFF' },
        ],
        USER_NEWSTAT: 'CHATOFF',

        TALK_CHN: [
          { CD_NM: '카카오톡', CD:'KAKAO' },
        ],
        TALK_NEWCHN:'KAKAO',

        dropItemsCategory: [
          { title: '이관'},
          { title: '예약'},
          { title: '콜백'},
          { title: '켐페인'},
        ],
        callCalendar: false,
        chatCalendar: false,
        chatCalendar2: false,
        timeStep: '00:00',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        chatmainCsEdit: true,
        chatmainChatEdit: true,
        chatmainCsPhone: [
          {
            title: '010-0000-0000',
          },
          {
            title: '010-1234-0000',
          },
        ],
        csDetailSearch: false,
        csDetailSearch2: false,
        dates: [
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        ],
        //고객상담이력
        chatHistory: [],

        CNSL_TY:[
          {CD: "", CD_NM: "전체"},
        ],
        CNSL_NEWTY:'',

        TALK_STAT:[
          {CD: '',CD_NM: '전체'}
        ],
        TALK_NEWSTAT:'',

        // 상담현황
        chatList: [],
        toggle_csCtBtn: 0,
        chatFilter: false,
        chatListFilter: 'all',
        csStatList: true,
        csStatChat: false,
        // 채팅 상담창
        talkHistory: [],
        scroll: 0,
        scrollTarget: null,
        chatDone: false,
        chatTemp: false,
        chatTrans: false,
        chatCallback: false,
        chatFontsize: false,

        CUST:this.fullCust(),

        ChatListIng:'',
        ChatListDone:'',
        ChatListAfter:'',
        ChatListWait:'',
        
        CLK_CUST:'',
        CLK_CHAT:'',
        CLK_USER:'',
        CLK_SENDER:'',
        CLK_MSGSKIP:'',
        CLK_STAT:'',

        CHT_RESULT:[],
        CHT_NEWRESULT:'',

        USER_NM: '',
        USER_TEL_NO:'',
        CHK_BL:false,
        USER_MEMO:'',

        talkHistList:[],

        CNSL_DIV: [
          {title: "전체", value: ""},
          {title: "대분류", value: "1"},
          {title: "중분류", value: "2"},
          {title: "소분류", value: "3"},
        ],

        CNSL_NEWDIV: '',
        SEARCH_CONT:'',

        page: 1,
        pageCount: 30,
        itemsPerPage: 5,
        totalVisible: 10,
        gridScriptHeader: [
          {
            text: "NO",
            align: "center",
            width: "30px",
            value: "INDEX",
          },
          {
            text: "분류",
            align: "center",
            width: "50px",
            value: "LVL_NO_NM",
          },
          {
            text: "스크립트명",
            align: "center",
            width: "100px",
            value: "SCRIPT_TIT",
          },
        ],
        gridScriptBody: [],

        SCP_DETTY_NM:'',
        SCP_DET_TIT:'',
        SCP_DET_CON:'',

        Imgdata:{},

        USER_ID: [],
        USER_NEWID: '',

        CNSL_TY1:[],
        CNSL_NEWTY1:'',
        CNSL_TY2:[],
        CNSL_NEWTY2:'',
        CNSL_TY3:[],
        CNSL_NEWTY3:'',
        CNSL_TY4:[],
        CNSL_NEWTY4:'',
        CHT_TIT:'',
        CHT_CON:'',

        CHAT_MSG:'',
        CNSL_RESULT:'',

        EXEC_RST_CD:'',
        CALL_TYP_CD:'',

        dialogTotalImage : false,

        img_src : '',
        scriptActive : false,
        selectedText : "",
        scriptTableHeight : 270,

        script_info         : {USE_YN : 'Y'} ,
        is_use_command : false,
      }
    },
    methods: {
      showAlertEndTalk(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '상담을 종료하시겠습니까?',
          iconClass: 'svg-chk-lg',
          type: 'confirm',
          callYes: this.callYesEndTalk,
          callNo: this.callNoEndTalk,
        });
      },

      callYesEndTalk(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
        this.chatDone = true;
        this.endTalk();
        this.getTalkList();
      },
      callNoEndTalk(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },

      endTalk(){
        apiChat.post("api/chat/main/end-mssage/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          system_id: '20180403040958193MSG34895',
          ASP_CUST_KEY: this.CLK_CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/end-mssage/inqire',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          let MSG_INFO = {'type' : 'system'
            , message_type : 'TX'
            , SNDRCV_CD: 'SND'
            , type : 'message'
            , consultEnd: 'Y'
            , msg : response.data.data
          };

          let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                          , 'talk_sender_key' : this.CLK_SENDER
                          , 'call_typ_cd' : this.CALL_TYP_CD
                          , 'aspNewCustKey' : this.CUST
                          , 'talk_user_key': this.CLK_USER
                          , 'SEND_USER_NM' : this.USER_NM
                          , 'token' : this.$store.state.messages.token
                          , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                          };

          let chatmsg = this.$store.getters['chatStore/getMsgObj',{
                        user_key: CHAT_INFO.talk_user_key
                        , sndrcv_cd: 'snd'
                        , type: MSG_INFO.message_type
                        , content: MSG_INFO.msg
                        , talk_contact_id: CHAT_INFO.talk_contact_id
                        , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                        , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                        , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                        , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                        , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                        //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                        , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                        , SEND_USER_SHORT_NM : '나'
                        , consultEnd : MSG_INFO.consultEnd
                        , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                    }];

          let msgObj = this.$store.getters['chatStore/getContactMsg', { talk_contact_id: CHAT_INFO.talk_contact_id }];

          stompUtil.sendMessage(MSG_INFO,CHAT_INFO,chatmsg,msgObj);
        })
        .catch((err) => {
          alert(err);
        })
      },

      // 스크립트 입력창 닫기 
      closeScriptForm() {
          this.is_use_command = false;
      },
      saveScriptForm() {
          //eventBus.closeScriptForm();
      },
      downloadExcel: function(history){
        var filename = this.CLK_USER + "_채팅내역";
        this.mixin_chat_exportExcel(history, filename);
      },
      // 스크립트 드래그 감지 함수
      selectTextScript: function(){
        var selectionObj = window.getSelection();
        this.selectedText = selectionObj.toString();
      },
      // 스크립트 버튼
      putScript: function(type){
        if(type === "new"){
          this.CHAT_MSG = (this.selectedText == null || this.selectedText =="" ? this.SCP_DET_CON : this.selectedText);
        }else{
          this.CHAT_MSG += "\n" + (this.selectedText == null || this.selectedText =="" ? this.SCP_DET_CON : this.selectedText);
        }
      },
      // 단축키 설정 버튼 클릭
      scriptFormClick: function(){
          this.is_use_command = !this.is_use_command;
      },
      // 스크립트 상세 닫기
      closeDetail: function(){
        this.scriptActive = false;
        this.scriptTableHeight = 270;
      },
      /**
       * 이미지 전체 보기 
       */
      setViewAllImg: function(message) {
        this.img_src  = this.getImageSrc(message);
        this.dialogTotalImage = true;
      },  

      /**
       * 이미지 src 가져오기
       */
      getImageSrc: function(message)  {
          var strImageUrl = "";

          const token = window.sessionStorage.getItem("token");
          var authHeader = "bearer " + token;
          axios.defaults.headers.common['Authorization'] = authHeader;

          strImageUrl = `/api/file/chat/images/${message.FILE_GROUP_KEY}/${message.FILE_KEY}`;

          return strImageUrl;


      },

      /**
       * 이미지 전체보기 창 닫기 
       */
      closeDialogTotalImage : function() {
          this.dialogTotalImage = false;
      },    

      //일시 모달
      formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${year}/${month}/${day}`
      },
      startDate(e) {
        this.dates[0] = e;
      },
      endDate(e) {
        this.dates[1] = e;
      },
      allowedStep: (m) => m % 10 === 0,
      chatmainCsCall(e) {
        console.log('전화번호1', e);
      },
      chatmainCsToggle(item) {
        console.log("Click Cnsl List!!!");
        console.log(item);
        this.getTalkHist(item.ID,!this.chatHistory[item.ID-1].EXPAND);
        this.getTalkHistList(item.ID-1,item.JOBBY_CNSL_HST_ID);
      },

      // 상담현황
      FnChatListAll() {
        this.chatListFilter = 'all'
      },
      FnChatListIng() {
        this.chatListFilter = 'ing'
      },
      FnChatListDone() {
        this.chatListFilter = 'done'
      },
      FnChatListAfter() {
        this.chatListFilter = 'after'
      },
      FnChatListWait() {
        this.chatListFilter = 'wait'
      },
      // 채팅상담 맨위로
      onScroll(e) {
        this.scroll = e.target.scrollTop;
      },
      scrlToTop() {
        this.scrollTarget = document.querySelector('.layout-chatmain--chat-history-box');

        if (this.scrollTarget) {
          this.scrollTarget.scrollTop = 0;
        }
      },
      scrlToBottom() {
        this.scrollTarget = document.querySelector('.layout-chatmain--chat-history-box');

        if (this.scrollTarget) {
          this.scrollTarget.scrollTop = this.scrollTarget.scrollHeight;
        }
      },
      FnChatHistoryTextsize(e) {
        this.chatHistoryTextsize = e;
        this.chatFontsize = false;
      },

      //상담원 전체 고객사 키
      fullCust(){
        let CUST = ''
        for(let i=0;i<this.$store.getters['userStore/GE_USER_ROLE'].company.length;i++){
          if(i == 0){
            CUST = this.$store.getters['userStore/GE_USER_ROLE'].company[i].CD
          } else {
            CUST = CUST + ',' + this.$store.getters['userStore/GE_USER_ROLE'].company[i].CD
          }
        }

        return CUST;
      },

      //상담원 상태 변경
      changeUserStat(showMsg){
        if(this.USER_NEWSTAT == "CHATOFF"){
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: '채팅OFF 시 메시지 고객한테 자료 받기!',
            iconClass: 'svg-chk-lg',
            type: 'confirm',
            callYes: this.chatOffYes,
            callNo: this.callNo1,
          })
        }else{        
          apiChat.post("api/chat/main/cnslr-sttus/regist",   //api url입력
          {
            USER_STATUS_CD: this.USER_NEWSTAT,
            ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            ASP_NEWCUST_KEY: this.CUST,
          },
          {head: {
            "SERVICE" : "setting.holiday.manage",
            "METHOD" : "list",
            "TYPE" : "BIZ_SERVICE",
            "ROW_CNT" : "200",
            "PAGES_CNT" : "1",
          }})
          .then((response) => {
            if(showMsg) {

              let count     = response.data.header.COUNT;
              let errorFlag = response.data.header.ERROR_FLAG;

              if (count != null && errorFlag == false) {
                  if(count >= 0) {
                      this.showAlertComplete( '상태가 변경 되었습니다.')
                      //console.log("this.dropItemsUserStatCd", this.dropItemsUserStatCd);
                      // if(this.dropItemsUserStatCd == 'CHATON'){
                      //   this.setTalkEnv(); // timeout 정보
                      //   this.connect(); // ready websocket 연결
                      // }
                  } else {
                    this.showAlertWarn( '상태가 실패 되었습니다.');
                  }
              }
            }
          })
          .catch((err) => {
            alert(err);
          })
        }
      },
      // chatoff [확인] 클릭
      chatOffYes() {
        console.log("chatOffYes::: " + this.EXEC_RST_CD);
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
        this.changeUserStatChatOff(); // TemPP
        // if(this.EXEC_RST_CD == '3' || this.EXEC_RST_CD == '4'){
        //   this.saveChat(this.EXEC_RST_CD);
        // } else {
        //   this.saveChatTemp();
        // }
      },

      changeUserStatChatOff(){

          apiChat.post("api/chat/main/cnslr-sttus/regist",   //api url입력
          {
            USER_STATUS_CD: this.USER_NEWSTAT,
            ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            ASP_NEWCUST_KEY: this.CUST,
          },
          {head: {
            "SERVICE" : "setting.holiday.manage",
            "METHOD" : "list",
            "TYPE" : "BIZ_SERVICE",
            "ROW_CNT" : "200",
            "PAGES_CNT" : "1",
          }})
          .then((response) => {

              let count     = response.data.header.COUNT;
              let errorFlag = response.data.header.ERROR_FLAG;

              if (count != null && errorFlag == false) {
                  if(count >= 0) {
                      this.showAlertComplete( '상태가 변경 되었습니다.')
                      //console.log("this.dropItemsUserStatCd", this.dropItemsUserStatCd);
                      // if(this.dropItemsUserStatCd == 'CHATON'){
                      //   this.setTalkEnv(); // timeout 정보
                      //   this.connect(); // ready websocket 연결
                      // }
                  } else {
                    this.showAlertWarn( '상태가 실패 되었습니다.');
                  }
              }
          })
          .catch((err) => {
            alert(err);
          })
        
      },

      //상담 목록 조회
      getTalkList(){
        console.log("Call getTalkList::");
        apiChat.post("api/chat/main/cnslt/inqire",   //api url입력
        {
          TYPE: 'ready;talking;callback;post;end',
          SORT_BY: 'TALK_DIST_DT',
          DECENDING: 'ASC',
          TALK_USER_KEY: '',
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
        },
        {head: {
        }})
        .then((response) => {
          console.log("결과>>>>>>>>>>>",response.data.data);
          this.chatList = response.data.data
          for (var i = 0; i < this.chatList.length; i++) {
            this.chatList[i]["index"] = i + 1;
            if(this.chatList[i]["CALL_TYP_CD"] == "KAKAOBZC"){
              this.chatList[i]["CHN_ICON"] = 'chat';
            }

            if(this.chatList[i].TALK_STAT_CD == "12"){
              this.chatList[i]["type"] = 'ing';
            }else if(this.chatList[i].TALK_STAT_CD == "21"){
              this.chatList[i]["type"] = 'done';
            }else if(this.chatList[i].TALK_STAT_CD == "20"){
              this.chatList[i]["type"] = 'after';
            }else if(this.chatList[i].TALK_STAT_CD == "11"){
              this.chatList[i]["type"] = 'wait';
            }
          }
          this.$store.dispatch("messages/AC_INIT_TALK_LIST", response.data.data).then(() => {
            this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : this.selected_stat, userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
          });

          this.scrlToBottom(); // 스크롤 내리기
        })
        .catch((err) => {
          console.log("결과<<<<<",err);
          alert(err);
        })
      },

      //상담 클릭 시 이벤트
      clickChat(i){

       console.log("First Event!!!!!!!!!!!!!!!!");

        this.CANT_CUST = true;
        if(this.chatList[i].TALK_STAT_CD == "20"){
          this.CANT_MODI = true;
          this.chatDone = true;
        } else {
          this.CANT_MODI = false;
          this.chatDone = false;
        }        
        this.getTalkList();  
        this.CLK_CUST=this.chatList[i].ASP_CUST_KEY;
        this.CLK_CHAT=this.chatList[i].TALK_CONTACT_ID;
        this.CLK_USER=this.chatList[i].TALK_USER_KEY;
        this.CLK_SENDER=this.chatList[i].ASP_SENDER_KEY;
        this.CLK_STAT=this.chatList[i].TALK_STAT_CD;
        this.CALL_TYP_CD = this.chatList[i].CALL_TYP_CD;
        this.CLK_MSGSKIP = this.chatList[i].SYSMSG_SKIP_YN;

        this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.getCnslTY("1", "*");

        this.getTalkInfo(); // 여기서 CONTACT INSERT
        this.getTalkMsg();
        this.getTalkHist();
        this.getUserInfo();
        this.getChatScript();
        this.getTalkList();  

        this.$forceUpdate();        
      },

      //상담 정보 조회
      getTalkMsg(){
        apiChat.post("api/chat/main/cnslt-info/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          TALK_CONTACT_ID: this.CLK_CHAT,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-info/inqire',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("채팅방 정보<<<<<",response.data.data);
          //if(response.data.data[0].SYSMSG_SKIP_YN != '' && response.data.data[0].SYSMSG_SKIP_YN != undefined){
          //  this.CLK_MSGSKIP = response.data.data[0].SYSMSG_SKIP_YN;
          //} else {
          //  this.CLK_MSGSKIP = 'N';
          //}
          //this.CNSL_NEWTY = response.data.data[0].CNSL_TYP_CD;
          //this.CNSL_NEWTY2 = response.data.data[0].CNSL_TYP_CD_2;
          //this.CNSL_NEWTY3 = response.data.data[0].CNSL_TYP_CD_3;
          //this.CNSL_NEWTY4 = response.data.data[0].CNSL_TYP_CD_4;
        })
        .catch((err) => {
          alert(err);
        })
      },

      //상담 상세 정보
      getTalkInfo(){
        console.log("getChatInfo:::: " + this.CLK_CHAT);
        apiChat.post("api/chat/main/cnslt-cn/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          TALK_CONTACT_ID: this.CLK_CHAT,
          TALK_USER_KEY: this.CLK_USER,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-cn/inqire',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("채팅정보>>>>>>>>",response.data.data);
          this.talkHistory = response.data.data;
          if(response.data.data != undefined){
            this.CLK_CHAT = response.data.data[0].TALK_CONTACT_ID;
            console.log("Contact(Return):::" + this.CLK_CHAT);
          }
          console.log("채팅창::: ");
          console.log(this.talkHistory);
          for (var i = 0; i < this.chatList.length; i++) {
            //this.talkHistory[i]["index"] = i + 1;
          }
        })
        .catch((err) => {
          alert(err);
        })
      },

      //상담 이력 조회
      getTalkHist(n,tf, chat){
        let DET_SRC = ''
        // let ST_DATE_FORM = this.DET_SRC_dates[0].replace(/-/g, '/');
        // let END_DATE_FORM = this.DET_SRC_dates[1].replace(/-/g, '/');
        // if(chat == 'detail'){
        //   DET_SRC = 'Y'
        // } else {
        //   DET_SRC = ''
        // }

        apiChat.post("api/chat/main/cnslt-hist/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          TALK_USER_KEY: this.CLK_USER,
          CNSL_DIV:'07',
          CSTMR_TELNO:'',
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-hist/inqire',
          method:'post',
          DET_SRC: DET_SRC,
          // ST_DATE: ST_DATE_FORM,
          // END_DATE: END_DATE_FORM,
          // RESULT: this.DET_SRC_RESULT,
          // AGENT_ID:this.DET_SRC_AGENT_ID,
          // CHENNEL: this.DET_SRC_CHENNEL,
          // CHAT_RESULT:this.DET_SRC_CHAT_RESULT,          
        },
        {head: {
        }})
        .then((response) => {
          console.log("상담이력<<<<>>>>",response.data.data);
          let LIST = this.chatHistory;
          this.chatHistory = response.data.data;
          for(let i=0;i<response.data.data.length;i++){
            if(n == '' || n == null){
              this.chatHistory[i]["EXPAND"] = false;
            } else{
              this.chatHistory[i]["EXPAND"] = LIST[i].EXPAND;
              this.chatHistory[n-1]["EXPAND"] = tf;
            }
            this.chatHistory[i]["ID"] = i+1;
            if(response.data.data[i].HST_TYPE == 'kakao'){
              this.chatHistory[i]["ICON"] = 'svg-chat-icon-kakao';
            } else if(response.data.data[i].HST_TYPE == 'line'){
              this.chatHistory[i]["ICON"] = 'svg-chat-icon-kakao';
            } else if(response.data.data[i].HST_TYPE == 'wechat'){
              this.chatHistory[i]["ICON"] = 'svg-chat-icon-wechat';
            } else if(response.data.data[i].HST_TYPE == 'ttalk'){
              this.chatHistory[i]["ICON"] = 'svg-chat-icon-ttalk';
            }

            if(this.chatHistory[i]["PROC_CODE_NM"] == '완결' || this.chatHistory[i]["PROC_CODE_NM"] == '전달'){
              this.chatHistory[i]["PROC_CODE"] = 'done';
            } else if(this.chatHistory[i]["PROC_CODE_NM"] == '미결'){
              this.chatHistory[i]["PROC_CODE"] = 'undone';
            } else if(this.chatHistory[i]["PROC_CODE_NM"] == '콜백'){
              this.chatHistory[i]["PROC_CODE"] = 'ing';
            }            
          }
        })
        .catch((err) => {
          alert(err);
        })
      },

      //고객정보 조회
      getUserInfo(){
        apiChat.post("api/chat/main/cstmr-info/inqire/"+this.CLK_USER,   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          CUSTOMER_ID: '',
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cstmr-info/inqire/'+this.CLK_USER,
          method:'post',
        },
        {head: {
        }})
        .then((response) => {
          console.log("사용자 정보>>>><<<<",response.data.data);
          this.USER_NM = response.data.data[0].CUSTOMER_NM
          this.USER_MEMO = response.data.data[0].MEMO
          this.USER_TEL_NO = response.data.data[0].CUSTOMER_PHONE_NO
          if(response.data.data[0].ATENT_CUSTOMER == 'Y')
            this.CHK_BL = true;
        })
        .catch((err) => {
          alert(err);
        })
      },

      //스크립트 조회
      getChatScript(){
        let DATE = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
        let ST_DATE = moment(DATE).subtract(1, "year").format("YYYY-MM-DD");
        let END_DATE = DATE;
        api.post("api/phone/script/inqire/list",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          USE_TY: 'CHAT',
          START_DATE: ST_DATE,
          END_DATE: END_DATE,
          SEARCH_CONT: this.SEARCH_CONT,
          CNSL_DIV:this.CNSL_NEWDIV,
          REQ_TY: '',
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cstmr-info/inqire/'+this.CLK_USER,
          method:'post'
        },
        {head: {
          "SERVICE": "phone.script.inqire.list",
          "METHOD": "list",
          "TYPE": "BIZ_SERVICE",
          "ASYNC": true,
          "PAGES_CNT": 1,
          "ROW_CNT": 100,
        }})
        .then((response) => {
          console.log("스크립트>>>><<<<",response.data.DATA);
          this.gridScriptBody = response.data.DATA;
          for(let i=0;i<this.gridScriptBody.length;i++){
            this.gridScriptBody[i]["INDEX"] = i+1;
          }
        })
        .catch((err) => {
          alert(err);
        })
      },

      // 스크립트 로우 클릭
      async scriptRowClick(item){
        this.SCP_ID = item.SCRIPT_ID;

        //this.ASP_SCPCUST_KEY = item.ASP_CUST_KEY;
        this.SCP_DETTY_NM = item.SCRIPT_NM_1 + ' > ' + item.SCRIPT_NM_2 + ' > ' + item.SCRIPT_NM_3;
        this.SCP_DET_TIT = item.SCRIPT_TIT;

        await api.post("api/phone/script/inqire/detail",
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          SCRIPT_ID: this.SCP_ID,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "/api/phone/script/inqire/detail",
          "METHOD" : "detail",
          "TYPE" : "BIZ_SERVICE",
          "ROW_CNT" : 100,
          "PAGES_CNT" : 1,
        }})
        .then((response) => {
          console.log("response.data", response.data);
          this.SCP_DET_CON = response.data.DATA[0].SCRIPT_RMK;
          this.scriptActive = true;
          this.scriptTableHeight = 270 - 107;

          console.log("TableChanged::: " + this.scriptTableHeight);
        })
        .catch((err) => {
          alert(err);
        });
      },

      isActiveRow_SCP(item){
        const activeClass = item.SCRIPT_ID === this.SCP_ID ? 'active' : '';
        return activeClass;
      },

      //상담이력 조회
      getTalkHistList(index,key){
        apiChat.post("api/chat/main/cnslt-hist/chat-inqire/"+key,   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-hist/chat-inqire/'+key,
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("상담이력<<<<>>>>",response.data.data);
          console.log("index", index);
          this.talkHistList[index] = response.data.data;
          console.log("이력메시지목록<<<<>>>>",this.talkHistList);
          this.$forceUpdate();
        })
        .catch((err) => {
          alert(err);
        })
      },

      //고객정보 저장
      regiUserInfo(){
        let BL = '';
        if (this.CHK_BL == true){
          BL = 'Y'
        } else if(this.CHK_BL == false){
          BL = 'N'
        }
        apiChat.post("api/chat/main/cstmr-info/regist/"+this.CLK_USER,   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          ASP_SENDER_KEY: this.CLK_SENDER,
          ATENT_CUSTOMER: BL,
          CUSTOMER_ID: this.CLK_USER,
          CUSTOMER_NM: this.USER_NM,
          CUSTOMER_PHONE_NO: this.USER_TEL_NO.replace(/\-/g,''),
          MEMO: this.USER_MEMO,
          TALK_USER_KEY: this.CLK_USER,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_NM: this.$store.getters['userStore/GE_USER_ROLE'].userNm,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cstmr-info/regist/'+this.CLK_USER,
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("사용자 정보>>>><<<<",response.data.data);
          this.success();
        })
        .catch((err) => {
          alert(err);
        })
      },

      //고객정보 저장 확인
      showAlertReg(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '고객정보를 저장하시겠습니까?',
          iconClass: 'svg-chk-lg',
          type: 'confirm',
          callYes: this.callYes1,
          callNo: this.callNo1,
        })
      },

      //고객정보 저장 [확인] 클릭
      callYes1() {
        this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
        });
        this.regiUserInfo();
      },

      //고객정보저장 [취소]클릭
      callNo1(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },

      //성공메시지
      success(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '정상처리 되었습니다.',
          iconClass: 'svg-done-lg',
          type: 'default'
        })
      },

      showDialog(menu) {
        let dataObj = {ASP_NEWCUST_KEY: this.CLK_CUST 
                      , CLK_CHAT:this.CLK_CHAT
                      , CLK_SENDER: this.CLK_SENDER
                      , CLK_CUST: this.CLK_CUST
                      , CLK_USER: this.CLK_USER
                      , USER_NM: this.USER_NM
                      };
        this.Imgdata = dataObj;
        console.log("safsfsafsa" , this.Imgdata);
        this[`Dialog${menu}`] = true;
      },

      hideDialog(menu) {
        this[`Dialog${menu}`] = false;
      },

      /** 경고메시지 */
      showAlertWarn(e){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: e,
          iconClass: 'svg-error-lg',
          type: 'default'
        })
      },
      /** 완료 메세지 */
      showAlertComplete(e){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: e,
          iconClass: 'svg-done-lg',
          type: 'default'
        })
      },

      //시스템 메시지 발송
      SystemMsgSkip(){
        let MSGSKIP_YN = '';
        if(this.CLK_MSGSKIP == 'Y'){
          MSGSKIP_YN = 'N';
        } else if(this.CLK_MSGSKIP == 'N'){
          MSGSKIP_YN = 'Y';
        }
        //console.log("SystemMsg 발송 중지:::: " + this.CLK_CHAT);
        apiChat.post("api/chat/main/un-rspns-mssage-sndng-setup/process",   //api url입력
        {
          TALK_CONTACT_ID: this.CLK_CHAT,
          SYSMSG_SKIP_YN: MSGSKIP_YN,
          ASP_NEWCUST_KEY: this.CUST,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/un-rspns-mssage-sndng-setup/process',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("시스템메시지 발송<<<<>>>>",response.data.data);
          if(MSGSKIP_YN == 'N'){
            this.successSysMsg('시스템 메시지 발송이 중지되었습니다.');
            this.CLK_MSGSKIP = 'N'
          } else if(MSGSKIP_YN == 'Y'){
            this.successSysMsg('시스템 메시지가 발송됩니다.');
            this.CLK_MSGSKIP = 'Y'
          }

        })
        .catch((err) => {
          alert(err);
        })
      },

      //시스템 메시지 발송 성공
      successSysMsg(successMsg){
        console.log("Systemmsg modal!!!");
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: successMsg,
          iconClass: 'svg-done-lg',
          type: 'default'
        })
      },

      //종료링크 보내기
      sendEndLink(){
        let strMsg = "고객님께 종료 버튼을 전송하였습니다.";
		    var MSG_INFO = {'msg' : strMsg, message_type : 'LI', SNDRCV_CD: 'SND', type : 'chatClose'};

        let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                        , 'talk_sender_key' : this.CLK_SENDER
                        , 'call_typ_cd' : this.CALL_TYP_CD
                        , 'aspNewCustKey' : this.CUST
                        , 'talk_user_key': this.CLK_USER
                        , 'SEND_USER_NM' : this.USER_NM
                        , 'token' : this.$store.state.messages.token
                        , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                        };

        let chatmsg = this.$store.getters['chatStore/getMsgObj',{
                      user_key: CHAT_INFO.talk_user_key
                      , sndrcv_cd: 'snd'
                      , type: MSG_INFO.message_type
                      , content: MSG_INFO.msg
                      , talk_contact_id: CHAT_INFO.talk_contact_id
                      , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                      , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                      , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                      , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                      , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                      //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                      , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                      , SEND_USER_SHORT_NM : '나'
                      , consultEnd : MSG_INFO.consultEnd
                      , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                  }];

        let msgObj = this.$store.getters['chatStore/getContactMsg', { talk_contact_id: CHAT_INFO.talk_contact_id }];
        console.log("chatmsg", chatmsg);
        console.log("msgObj", msgObj);

		    stompUtil.sendMessage(MSG_INFO,CHAT_INFO,chatmsg,msgObj);
      },

      //전달 시 채팅상담 가능 상담사 목록 조회
      getUserSelect(){
        apiChat.post("api/chat/common-vue/chaton-agents/list",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          KEYWORD: '',
          ASP_CUST_KEY: this.CLK_CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/common-vue/chaton-agents/list',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("사용자 선택박스 생성<<<<>>>>",response.data.data);
          this.USER_ID = response.data.data[0];
        })
        .catch((err) => {
          alert(err);
        })
      },

      //전달 확인
      confirmTrans(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: this.USER_NEWID+'님께 채팅을 전달하시겠습니까?',
          iconClass: 'svg-chk-lg',
          type: 'confirm',
          callYes: this.chatTransToUser,
          callNo: this.callNo1,
        })
      },

      //채팅 전달
      chatTransToUser(){
        apiChat.post("api/stomp/chat/agent/forwarding",   //api url입력
        {
          CNSL_TYP_CD: this.CNSL_NEWTY1,
          CNSL_TYP_CD_2: this.CNSL_NEWTY2,
          CNSL_TYP_CD_3: this.CNSL_NEWTY3,
          CNSL_TYP_CD_4: this.CNSL_NEWTY4,
          EXEC_RST_CD: '3',
          TALK_ANS: this.CHT_TIT,
          TALK_QST: this.CHT_CON,
          TALK_CONTACT_ID: this.CLK_CHAT,
          agent_id: this.USER_NEWID,
          ASP_NEWCUST_KEY: this.CLK_CUST,
          ASP_CUST_KEY: this.CLK_CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/stomp/chat/agent/forwarding',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          this.getTalkList();
        })
        .catch((err) => {
          alert(err);
        })
      },

      //상담유형1
      chnCnsl1() {
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.getCnslTY("2", this.CNSL_NEWTY1);
      },
      //상담유형2
      chnCnsl2() {
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.getCnslTY("3", this.CNSL_NEWTY2);
      },
      //상담유형3
      chnCnsl3() {
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.getCnslTY("4", this.CNSL_NEWTY3);
      },

      //사용자 상담유형 콤보박스 생성
    getCnslTY(num, cnsl) {
      if (num == "1") {
        this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY1 = "";
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY2 = "";
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = "*";
      } else if (num == "2") {
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY2 = "";
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      } else if (num == "3") {
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      } else if (num == "4") {
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      }
      api
        .post(
          "api/setting/consulttype/manage/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CUST,
            SPST_CNSL_TYP_CD: cnsl,
            USE_YN: "Y",
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "setting.consulttype.manage",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          if (num == "1") {
            this.CNSL_TY1 = [...this.CNSL_TY1, ...response.data.DATA];
          } else if (num == "2") {
            if (this.CNSL_NEWTY1 == "") {
              this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY2 = "";
              this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY3 = "";
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY2 = [...this.CNSL_TY2, ...response.data.DATA];
            }
          } else if (num == "3") {
            if (this.CNSL_NEWTY2 == "") {
              this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY3 = "";
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY3 = [...this.CNSL_TY3, ...response.data.DATA];
            }
          } else if (num == "4") {
            if (this.CNSL_NEWTY3 == "") {
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY4 = [...this.CNSL_TY4, ...response.data.DATA];
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
      },

      //상담유형 초기화
      initCnsl(){
        this.CNSL_NEWTY1 = '';
        this.CNSL_NEWTY2 = '';
        this.CNSL_NEWTY3 = '';
        this.CNSL_NEWTY4 = '';
      },

      //메시지 전송
      sendMsg(){
        let msgcopy = this.CHAT_MSG
        if (msgcopy.replace(/(?:\r\n|\r|\n)/g, '').length === 0) {
          return false;
        }

        let MSG_INFO = {'msg' : msgcopy, message_type : 'TX', SNDRCV_CD: 'SND', type : 'message'}
        let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                        , 'talk_sender_key' : this.CLK_SENDER
                        , 'call_typ_cd' : this.CALL_TYP_CD
                        , 'aspNewCustKey' : this.CUST
                        , 'talk_user_key': this.CLK_USER
                        , 'SEND_USER_NM' : this.USER_NM
                        , 'token' : this.$store.state.messages.token
                        , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                        };

        let chatmsg = this.$store.getters['messages/GE_MSG_OBJ']({
                      user_key: CHAT_INFO.talk_user_key
                      , sndrcv_cd: 'snd'
                      , type: MSG_INFO.message_type
                      , content: MSG_INFO.msg
                      , talk_contact_id: CHAT_INFO.talk_contact_id
                      , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                      , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                      , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                      , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                      , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                      //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                      , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                      , SEND_USER_SHORT_NM : '나'
                      , consultEnd : MSG_INFO.consultEnd
                      , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                  });

        let msgObj = this.$store.getters['messages/GE_CONTACT_MSG']({talk_contact_id: CHAT_INFO.talk_contact_id });
        console.log("chatmsg1111", chatmsg);
        console.log("msgObj222222222", msgObj);

        stompUtil.sendMessage(MSG_INFO,CHAT_INFO, chatmsg, msgObj)
        this.CHAT_MSG = '';

        this.getTalkList();
        this.getTalkInfo();
      },

      //상담이력 저장(임시저장,후처리,콜백,)
      saveChat(num){
        let TIME = this.computedDateFormatted2;
        let TIME_ARR = TIME.split("-");
        let TIME_ARR_ARR = TIME_ARR[2].split(" ");
        let TIME_ARR_ARR_ARR = TIME_ARR_ARR[1].split(":");

        apiChat.post("api/chat/main/cnslt-hist/regist",   //api url입력
        {
          CNSL_TYP_CD: this.CNSL_NEWTY1,
          CNSL_TYP_CD_2: this.CNSL_NEWTY2,
          CNSL_TYP_CD_3: this.CNSL_NEWTY3,
          CNSL_TYP_CD_4: this.CNSL_NEWTY4,
          EXEC_RST_CD: num,
          TALK_ANS: this.CHT_TIT,
          TALK_QST: this.CHT_CON,
          TALK_CONTACT_ID: this.CLK_CHAT,
          TALK_STAT_CD: 12,
          CALLBACK_YMD: TIME_ARR[0]+TIME_ARR[1]+TIME_ARR_ARR[0],//여기여깅겨ㅣㅇ
          CALLBACK_HH: TIME_ARR_ARR_ARR[0],//여기여기여기
          CALLBACK_MM:TIME_ARR_ARR_ARR[1],//여기여기여기
          CNSL_DIV:'07',//여기여기여기
          CSTMR_TELNO: this.USER_TEL_NO,//여기여기여기
          CSTMR_ID: this.CLK_USER,//여기여기여기
          ASP_NEWCUST_KEY: this.CUST,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-hist/regist',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          if(this.CLK_STAT == '12' && num != '4'){
            apiChat.post("api/chat/main/end-mssage/inqire",   //api url입력
            {
              ASP_NEWCUST_KEY: this.CLK_CUST,
              system_id: '20180403040958193MSG34895',
              ASP_CUST_KEY: this.CUST,
              USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
              ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
              action:'api/chat/main/end-mssage/inqire',
              method:'post'
            },
            {head: {
            }})
            .then((response) => {
              let MSG_INFO = {'type' : 'system'
                , message_type : 'TX'
                , SNDRCV_CD: 'SND'
                , type : 'message'
                , consultEnd: 'Y'
                , msg : response.data.data
              };

              let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                              , 'talk_sender_key' : this.CLK_SENDER
                              , 'call_typ_cd' : this.CALL_TYP_CD
                              , 'aspNewCustKey' : this.CUST
                              , 'talk_user_key': this.CLK_USER
                              , 'SEND_USER_NM' : this.USER_NM
                              , 'token' : this.$store.state.messages.token
                              , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                              };

              let chatmsg = this.$store.getters['chatStore/getMsgObj',{
                            user_key: CHAT_INFO.talk_user_key
                            , sndrcv_cd: 'snd'
                            , type: MSG_INFO.message_type
                            , content: MSG_INFO.msg
                            , talk_contact_id: CHAT_INFO.talk_contact_id
                            , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                            , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                            , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                            , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                            , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                            //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                            , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                            , SEND_USER_SHORT_NM : '나'
                            , consultEnd : MSG_INFO.consultEnd
                            , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                        }];

              let msgObj = this.$store.getters['chatStore/getContactMsg', { talk_contact_id: CHAT_INFO.talk_contact_id }];

              stompUtil.sendMessage(MSG_INFO,CHAT_INFO,chatmsg,msgObj);
            })
            .catch((err) => {
              alert(err);
            })
          } else {
            if(num == '4'){
              this.successSysMsg('콜백이 저장되었습니다.');

              this.getTalkList();

              this.CHT_NEWRESULT='';
              this.CNSL_NEWTY1='';
              this.CNSL_NEWTY2='';
              this.CNSL_NEWTY3='';
              this.CNSL_NEWTY4='';
              this.CHT_TIT='';
              this.CHT_CON='';
              this.date=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
              this.timeStep='00:00';
            } else {
              this.successSysMsg('상담이력이 저장되었습니다.');
              this.chatDone = false

              this.getTalkList();

              this.talkHistory=[];

              this.CLK_CUST='';
              this.CLK_CHAT='';
              this.CLK_USER='';
              this.CLK_SENDER='';
              this.CLK_MSGSKIP='';
              this.CLK_STAT='';

              this.CHT_NEWRESULT='';

              this.USER_NM= '';
              this.USER_TEL_NO='';
              this.CHK_BL=false;
              this.USER_MEMO='';

              this.chatHistory=[];

              this.CNSL_NEWDIV= '';
              this.SEARCH_CONT='';

              this.gridScriptBody=[];
              this.SCP_DETTY_NM='';
              this.SCP_DET_TIT='';
              this.SCP_DET_CON='';

              this.USER_NEWID= '';

              this.CNSL_NEWTY1='';
              this.CNSL_NEWTY2='';
              this.CNSL_NEWTY3='';
              this.CNSL_NEWTY4='';
              this.CHT_TIT='';
              this.CHT_CON='';

              this.MSG='';
            }

            this.getTalkList();

            this.talkHistory=[];

            this.CLK_CUST='';
            this.CLK_CHAT='';
            this.CLK_USER='';
            this.CLK_SENDER='';
            this.CLK_MSGSKIP='';
            this.CLK_STAT='';

            this.CHT_NEWRESULT='';

            this.USER_NM= '';
            this.USER_TEL_NO='';
            this.CHK_BL=false;
            this.USER_MEMO='';

            this.chatHistory=[];

            this.CNSL_NEWDIV= '';
            this.SEARCH_CONT='';

            this.gridScriptBody=[];
            this.SCP_DETTY_NM='';
            this.SCP_DET_TIT='';
            this.SCP_DET_CON='';

            this.USER_NEWID= '';

            this.CNSL_NEWTY1='';
            this.CNSL_NEWTY2='';
            this.CNSL_NEWTY3='';
            this.CNSL_NEWTY4='';
            this.CHT_TIT='';
            this.CHT_CON='';

            this.MSG='';
          }
          
        })
        .catch((err) => {
          alert(err);
        })
      },

      //임시저장
      saveChatTemp(){
        apiChat.post("api/chat/main/cnslt-hist-tmpr/regist",   //api url입력
        {
          CNSL_TYP_CD: this.CNSL_NEWTY1,
          CNSL_TYP_CD_2: this.CNSL_NEWTY2,
          CNSL_TYP_CD_3: this.CNSL_NEWTY3,
          CNSL_TYP_CD_4: this.CNSL_NEWTY4,
          EXEC_RST_CD: '',
          TALK_ANS: this.CHT_TIT,
          TALK_QST: this.CHT_CON,
          TALK_CONTACT_ID: this.CLK_CHAT,
          ASP_NEWCUST_KEY: this.CLK_CUST,
          ASP_CUST_KEY: this.CLK_CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-hist-tmpr/regist',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          this.successSysMsg('상담이력이 임시저장되었습니다.');
          this.chatTemp=false;
        })
        .catch((err) => {
          alert(err);
        })
      },

      //상담저장 확인
      confirmSave(num, saveMsg){
        this.EXEC_RST_CD = num;
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: saveMsg,
          iconClass: 'svg-chk-lg',
          type: 'confirm',
          callYes: this.callSaveYes,
          callNo: this.callNo1,
        })
      },

      // 상담 저장 [확인] 클릭
      callSaveYes() {
        console.log("Confirm Cnsl Save::: " + this.EXEC_RST_CD);
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
        this.saveChat(this.EXEC_RST_CD); // TemPP
        // if(this.EXEC_RST_CD == '3' || this.EXEC_RST_CD == '4'){
        //   this.saveChat(this.EXEC_RST_CD);
        // } else {
        //   this.saveChatTemp();
        // }
      },

      //
      //220530 웹소켓 이벤트 추가
       
      /**
        * websocket connect 
        * @param connectCallback   연결 성공 콜백 
        * @param errorCallback     연결 실패 콜백 ( 재연결 시도 )
        */
      connect() {
          
          let geUserId = this.$store.getters["userStore/GE_USER_ID"];

          //const subScribeUrl = this.baseSocketUrl + "queue/ready/" + geUserId;                //"http://local.hkpalette.com:8443/queue/ready/"
          let subScribeUrl = "/queue/ready/" + geUserId; 

          let chatMessage = {
            chatType: "READY",
            userId: geUserId,
            userName: geUserId,
            userNickname: geUserId
          };
          
          stompUtil.token         = this.$store.state.messages.token;                                //SOCKET 연결 시 사용자 SOCKET Token 세팅 
          stompUtil.userId        = geUserId;
          stompUtil.aspNewCustKey = this.CUST;

          stompUtil.connect(() => {   // 연결성공( 재연결 포함 )
                  
            //this.connected = true;
            console.log("nononon>>>");
            
            stompUtil.subscribe(subScribeUrl, chatMessage); 
            let subList = this.$store.getters['messages/GE_SUBSCRIPTION_LIST'];   // ready uri sub
            console.log("subList!!!!", subList)
            stompUtil.subscribeAfterReconnect(subList);      // 재연결 후 기존 sub 목록을다시 sub 해줌.최초는 대상이 없으므로 무관
            
            this.getTalkers();                    // 웹소켓 연결 후 상담목록 조회 
                  
            ///// 선택된 상담이 잇다면 재조회 (재연결시...)
            ////if( this.store.getters.getContactInfo().talk_contact_id != null && .getters.getContactInfo().talk_contact_id != '' ) {
            /////  eventBus.refreshChatContent(this.store.getters.getContactInfo().talk_contact_id); 
            ////}
          }, 
          (error) => { //연결실패 

            if( error != null) {

              //console.log(error);
              stompUtil.setConnected(false);

              // 재연결 시도 
              setTimeout(this.connect, 3000);
            }
          }
        );
      },
      
      // 웹소켓 연결종료
      disconnect() {
        if (stompUtil.isConnected()) {
          stompUtil.disconnect();
        }
      },

      /**
       * ready callback 함수  
       */
      readyCallback(message) {
        
        console.log("M110101 readyCallback message.body >>>> " + message.body);

        if (typeof message.body !== "undefined") {

          let recvJson = JSON.parse(message.body);
          let data     = JSON.parse(recvJson.telewebJsonString);
          let uri      = message.headers.destination;
          
          console.log("M110101 readyCallback recvJson.chatType >>>> " + recvJson.chatType);

          if (recvJson.chatType == "READY" && recvJson.chatEvent == "AGENT_READY") {
            let sessionId = recvJson.sessionId;
            this.$store.state.messages.simsessionId = sessionId;        
          }
          
          console.log("M110101 readyCallback data.DATA.length >>>> " + data.DATA.length);

          if (data.DATA != null && data.DATA.length > 0) {

            let i = 0;
            let dataLen = data.DATA.length;

            for (; i < dataLen; i++) {
                        
              let talkUserKey = data.DATA[i].TALK_USER_KEY;
              let userKey = data.DATA[i].user_key; 
              
              console.log("M110101 readyCallback talkUserKey >>>> " + talkUserKey);

              if (dataLen > 0 && ((talkUserKey != null && talkUserKey) || (userKey != null && userKey))) {
                
                // 상담종료 메시지 보여줌. unsubscription ( 필요없음 redis 삭제 되므로.)
                if (recvJson.chatEvent == 'EXPIRED_SESSION_CUST') {
                  
                  data.DATA[i].uri = uri;
                  
                  this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[i]).then(() => {
                    this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
                  });     // 목록 삭제 (구독해제 포함)

                  //Vue.prototype.store.dispatch('spliceTalker', data.DATA[i]).then(() => {
                  //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : 'mixin'});
                  //});     // 목록 삭제 (구독해제 포함)
                }   
                else {
                  
                  console.log("M110101 readyCallback talkUserKey else >>>> ");
                      
                  // data.DATA[i].TALK_STAT_NM = "대기중";
                  data.DATA[i].TALK_STAT_NM = this.mixin_get_talk_statnm(data.DATA[i].TALK_STAT_CD);
                  data.DATA[i].USER_ID = recvJson.userId;
                  data.DATA[i].AUTO_GREETING_YN = data.DATA[i].TALK_STAT_CD == '11' || data.DATA[i].TALK_STAT_CD == '13'? 'N' : 'Y';

                  this.$store.dispatch("messages/AC_UN_SHIFT_TALKER", data.DATA[i]).then(() => {
                    this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
                  }); 

                  //Vue.prototype.store.dispatch('unshiftTalker', data.DATA[i]).then(() => {
                  //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                  //});

                  //this.getTalkers();      //신규 메시지를 받으면 새로고침
                  this.getTalkList();
                }
              }
            }
          }
        }
      },

      /**
       *  inout callback 함수  
       */   
      inoutCallback(message) {
            
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 13", this.CUST);

        if (typeof (message.body) !== "undefined") {
 
          let recvJson = JSON.parse(message.body);
          let data = JSON.parse(recvJson.telewebJsonString);
          let uri = message.headers.destination;
                 
          if (data.DATA.length > 0 && data.DATA[0].user_key != null && data.DATA[0].user_key != '') {
 
            data.DATA[0].uri = uri;
 
            if (data.DATA[0].SNDRCV_CD == null || data.DATA[0].SNDRCV_CD == '') {
              data.DATA[0].sndrcv_cd = 'rcv';
            }
 
            // 자신이 보낸 메시지는 스킵 처리 한다.( 구독자 모두에게 다시 전송 되므로..)
            if (data.DATA[0].SNDRCV_CD == 'SND' && data.DATA[0].USER_ID == this.$store.getters["messages/GE_USER_ID"]) {   //Vue.prototype.store.getters.getUserId()
              return;
            }
                     
            // 제3자 채팅
            if (data.DATA[0].SNDRCV_CD == 'TRAN') {
              data.DATA[0].content = data.DATA[0].message;
            } 
                     
            // 무응답은 error_msg 가 메시지 내용임.
            // console.log("obj.inoutCallback >> " + recvJson.attachment + '-' + recvJson.org_cont_id  + '-' + data.DATA[0].attachment + '-' + data.DATA[0].org_cont_id  + '-' );
            if (recvJson.chatEvent == 'NO_CUSTOMER_RESPONSE' || recvJson.chatEvent == 'EXPIRED_AGENT_SESSION') {
              data.DATA[0].content = data.HEADER.ERROR_MSG;
              data.DATA[0].sndrcv_cd = 'snd';
              data.DATA[0].USER_NAME = 'SYSTEM';
            }

            if (typeof data.DATA[0].content == "object" || Array.isArray(data.DATA[0].content)){
              data.DATA[0].content = JSON.stringify(data.DATA[0].content);
            }
 
            let msg = this.$store.getters["messages/GE_MSG_OBJ"](data.DATA[0]);                                                             // Vue.prototype.store.getters.getMsgObj(data.DATA[0]);
            let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({ talk_contact_id: data.DATA[0].TALK_CONTACT_ID });                 //Vue.prototype.store.getters.getContactMsg
                     
            // 상담종료 메시지 보여줌. unsubscription ( 필요없음 redis 삭제 되므로.)
            if (recvJson.chatEvent == 'EXPIRED_SESSION_CUST') {
 
              // 상담중 완료
              let talkerObj = _.find(this.$store.getters["messages/GE_TALK_LIST"], { 'TALK_CONTACT_ID': data.DATA[0].TALK_CONTACT_ID });    //Vue.prototype.store.getters.getTalkList()
              
              // console.log("obj.EXPIRED_SESSION_CUST >> " + talkerObj + '-' + data.DATA[0].TALK_CONTACT_ID);
              if (talkerObj != null && talkerObj != '' && data.DATA[0].TALK_CONTACT_ID != null) {     // 이미 상담 시작됨.
                
                talkerObj.TALK_STAT_CD = data.DATA[0].TALK_STAT_CD
                //talkerObj.TALK_STAT_NM = this.getTalkStatName(data.DATA[0].TALK_STAT_CD);
                talkerObj.TALK_STAT_NM = this.mixin_get_talk_statnm(data.DATA[0].TALK_STAT_CD);
 
                msg.msg_notice = "상담이 종료되었습니다. 상담이력을 저장해 주시기 바랍니다.";  

                this.$store.dispatch("messages/AC_SUB_TALKER", data.DATA[0]).then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
                });     // 구독해제

                //Vue.prototype.store.dispatch('AC_SUB_TALKER', data.DATA[0]).then(() => {
                //  Vue.prototype.store.dispatch("AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : ''});
                //});     // 구독해제

              } else {    // 대기중         
                             
                this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[0]).then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
                });     // // 대기중 완료 목록 삭제 (구독해제 포함)    

                //Vue.prototype.store.dispatch('spliceTalker', data.DATA[0]).then(() => {
                //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                //});     // // 대기중 완료 목록 삭제 (구독해제 포함)    
              }
                         
              // 현재 선택 채팅이 변경 된 경우만 변경 처리  this.$store.state.messages.talkContactInfo.talk_contact_id
              if( data.DATA[0].TALK_CONTACT_ID == this.$store.state.messages.talkContactInfo.talk_contact_id) {
                this.changeChatInfo();
              }

              this.chatDone = true;
              this.CLK_STAT = data.DATA[0].TALK_STAT_CD;
              window.focus();
            }
            else if (recvJson.chatEvent == 'EXPIRED_AGENT_SESSION') {         // 전달완료 (목록에서 삭제 )

              this.$store.dispatch("messages/AC_SPICE_TALKER",data.DATA[0]).then(() => {
                this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
 
                if( data.DATA[0].TALK_CONTACT_ID == this.$store.state.messages.talkContactInfo.talk_contact_id) {
                  this.changeChatInfo();
                }
              });     // 목록 삭제 (구독해제 포함)    

              //Vue.prototype.store.dispatch('spliceTalker', data.DATA[0]).then(() => {
              //  Vue.prototype.store.dispatch("AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
              //  if( data.DATA[0].TALK_CONTACT_ID == Vue.prototype.store.state.messages.talkContactInfo.talk_contact_id) {
              //    eventBus.changeChatInfo(()=>{});
              //  }
              //});     // 목록 삭제 (구독해제 포함)
                         
              return;
            }    

            this.$store.dispatch("messages/AC_USER_CHAT_MESSAGE", { "msg": msg, "msgObj": msgObj, ASP_NEWCUST_KEY : this.CUST})
            //Vue.prototype.store.dispatch("userchatMessage", { "msg": msg, "msgObj": msgObj });
                     
          }
        }
      },
      
      /****************************************************************************************************************************************************
         * 챗 Stomp변수 / 함수 정보 END 
       ****************************************************************************************************************************************************
       ***************************************************************************************************************************************************/

      /****************************************************************************************************************************************************
         * 챗 변수 / 함수 정보 START
       ***************************************************************************************************************************************************/
      

      /** 챗 사용자정보 세팅 */
      setChatUserInfo() {
        
        apiChat.post("api/stomp/chat/userinfo", {
          USER_ID: this.$store.getters['userStore/GE_USER_ID'],
          USER_NM: this.$store.getters['userStore/GE_USER_NAME']
        })
        .then((ret) => {

          // 서버 error 체크 
          if(ret.data.error != null && ret.data.error != '') {

              if(ret.data.error == '-999') {
                this.showAlertWarn( '챗 상담 사용자 정보 Error(Talk Token)');    
              }
          } 
          else {
            
            this.$store.state.messages.token = ret.data.token;
            this.$store.state.messages.userId = ret.data.id;
            this.$store.state.messages.userNm = ret.data.name;
            this.$store.state.messages.aspCustKey = ret.data.aspCustKey;
            
            this.setTalkEnv();  // timeout 정보 
            this.connect();     // ready websocket 연결 
          }
        });
      },

      /** 상담설정 관련 정보 get */ 
      setTalkEnv() {

        apiChat.post("api/chat/common-vue/env/inqry", {
          ASP_NEWCUST_KEY : this.CUST
        })
        .then((ret) => {

          // 서버 error 체크 
          if(ret.data.error != null && ret.data.error != '') {

              if(ret.data.error == '-999') {
                this.showAlertWarn( '챗 상담 사용자 정보 Error(Talk Token)');    
              }
          } 
          else {
            
            let retObj = Object.values(ret.data.data);

            if(retObj != null && retObj.length > 0) {
               this.$store.state.readyTimeout  = retObj[0].RETRY_READY_MAX_TIMEOUT;
            }

            if(retObj != null && retObj.length > 0) {
              this.$store.state.messages.blobYn  = retObj[0].BLOB_YN;
            }  
          }
        });
      },

      changeChatInfo() {
        this.getTalkMsg((ret) => {
             
          if(ret != null && ret != '')   {
            let talkContactInfo = {
              talk_contact_id : ret.TALK_CONTACT_ID,
              talk_user_key   : ret.TALK_USER_KEY,
              talk_sender_key : ret.TALK_SENDER_KEY,
              call_typ_cd     : ret.CALL_TYP_CD,
              user_id			: ret.USER_ID
            };

            this.callTypCd = ret.CALL_TYP_CD;
                  
            if( stompUtil.stompClient == null || stompUtil.stompClient == '')	{
              stompUtil.connect(() => {}, 
                          (error) => {
                              if( error != null) {
                                  //console.log(error);
                                  //this.connected = false;
                              }
                          }, talkContactInfo);
            }
                  
            // 상담이력폼 초기화 
            ///// Arthur.Kim if(this.cnslSaveFrame && this.$refs.talkHistoryForm != null) {
            ///// Arthur.Kim   this.$refs.talkHistoryForm.initForm(this.talkContactId);
            ///// Arthur.Kim }  

            this.msg = "";  // 초기화 
          }

          next();

		    });
      },

      /** 채팅 클릭시 채팅정보 검색 */
      getTalkers() {

        apiChat.post("api/chat/main/cnslt/inqire", {
          TYPE: 'ready;talking;callback;post',
          SORT_BY: 'TALK_DIST_DT',
          DECENDING: 'ASC',
          TALK_USER_KEY: '',
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
        })
        .then((ret) => {

          // 서버 error 체크 
          if(ret.data.error != null && ret.data.error != '') {

              if(ret.data.error == '-999') {
                this.showAlertWarn( '상세 목록 조회 Error');    
              }
          } 
          else {
            
            // this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[i])
            if( ret.data.code >= 0) {

              this.$store.dispatch("messages/AC_INIT_TALK_LIST", ret.data.data).then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : this.selected_stat, userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
              });
            }
          }  
        });
      },
      
      //220530 웹소켓 이벤트 추가
      //
    },

    computed: {
      dateRangeText () {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + ' ~ ' + newEndDate;
        // return this.dates.join(' ~ ')
      },
      computedDateFormatted(){
        return this.formatDate(this.date)
      },
      computedDateFormatted2() {
        return `${this.date} ${this.timeStep}`
      },
      chatListIng(){
        return this.chatList.filter(arr => arr.type === 'ing' );
      },
      chatListDone(){
        return this.chatList.filter(arr => arr.type === 'done' );
      },
      chatListAfter(){
        return this.chatList.filter(arr => arr.type === 'after'  || arr.type === 'after countdown');
      },
      chatListWait(){
        return this.chatList.filter(arr => arr.type === 'wait' );
      },
      chatListReseve(){
        return this.chatList.filter(arr => arr.statChat === true );
      },

      // 새로붙이기 단축키 설명
      newCommandDesc : function() {
          
          if(this.script_info.NEW_COMMAND == null || this.script_info.NEW_COMMAND == '')
              this.new_command_desc = '';
          else if(this.new_command_desc != null && this.new_command_desc != '')
              this.new_command_desc = "("+this.new_command_desc+")"

          return this.new_command_desc;
      },

      // 이어붙이기 단축키 설명
      addCommandDesc : function() {
          
          if(this.script_info.ADD_COMMAND == null || this.script_info.ADD_COMMAND == '')
              this.add_command_desc = '';
          else if( this.add_command_desc != null && this.add_command_desc != '')
              this.add_command_desc = "("+this.add_command_desc+")"

          return this.add_command_desc;
      },      
    },

    created() {

      /****************************************************************************************************************************************************
       * 공통 변수 / 함수 정보 START 
       ***************************************************************************************************************************************************/ 
      
      /** 현재시간 추출 (1/10000 초)  */
      setInterval(() => {            
        this.nowTime = moment();
      }, 10000);

      /****************************************************************************************************************************************************
       * 공통 변수 / 함수 정보 END 
       ****************************************************************************************************************************************************
       ***************************************************************************************************************************************************/

      /**
       * 이벤트 호출
       * Stomp Disconnect  
       */ 
      eventBus.$on('busStompDisconnect', () => {
        this.disconnect();
      });

      /**
       * 상담원 대기 구독 메시지 인입 
       */
      eventBus.$on('busReadyCallback', (message) => {
        this.readyCallback(message);
        this.getTalkList();
      });

      /**
       * 채팅 메시지 인입 
       */
      eventBus.$on('busInoutCallback', (message) => {
        this.inoutCallback(message);
        // let recvJson = JSON.parse(message.body);

        // if(recvJson.chatEvent == 'EXPIRED_SESSION_CUST'){
        //     chatDone = true;
        // }
        this.getTalkList();
        this.getTalkInfo();
      });

      /**
       * 채팅 메시지 전송 
       */
      eventBus.$on('userchatMessage', (message) => {
        console.log("message><><><", message);
        this.$store.dispatch("messages/AC_USER_CHAT_MESSAGE", message);
        //chatStore.userchatMessage(message);
      });
    },

    async mounted() {
      let codeName1 = ["TALK030"];
      let common_code1 = await this.mixin_common_code_get_all(codeName1, "Y");
      this.USER_STAT = common_code1[0].group_value;

      let codeName2 = ["TALK006"];
      let common_code2 = await this.mixin_common_code_get_all(codeName2, "Y");
      this.TALK_STAT = [...this.TALK_STAT, ...common_code2[0].group_value];

      let codeName3 = ["PLT001"];
      let common_code3 = await this.mixin_common_code_get_all(codeName3, "Y");
      this.CNSL_TY = [...this.CNSL_TY, ...common_code3[0].group_value];

      let codeName4 = ["TALK005"];
      let common_code4 = await this.mixin_common_code_get_all(codeName4, "Y");
      this.CHT_RESULT = common_code4[0].group_value;

      window.addEventListener ('unload', this.disconnect());              //this.calculateWindowWidth();
      window.addEventListener("keydown", this.setAvailableRefresh); 
      this.setChatUserInfo();
      this.getTalkList();
    },

    watch: {
    }
  };
</script>

<style>

</style>
